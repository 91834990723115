import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { Flex, Box, Text } from 'rebass/styled-components'
import { graphql, useStaticQuery } from 'gatsby'

const types = [
  {
    id: 1,
    title: 'Typ A',
    size: 128,
    imageKey: 'typ-a',
  },
  {
    id: 2,
    title: 'Typ B',
    size: 101,
    imageKey: 'typ-b',
  },
  {
    id: 3,
    title: 'Typ C',
    size: 103,
    imageKey: 'typ-c',
  },
  {
    id: 4,
    title: 'Typ D',
    size: 121,
    imageKey: 'typ-d',
  },
  {
    id: 5,
    title: 'Typ E',
    size: 118,
    imageKey: 'typ-e',
  },
  {
    id: 6,
    title: 'Typ F',
    size: 138,
    imageKey: 'typ-f',
  },
]

const HouseType = ({ title, size, image }) => {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        width: '100%',
      }}
    >
      {image && (
        <Box sx={{ mb: 3 }}>
          <Img fluid={image.childImageSharp.fluid} alt={title} />
        </Box>
      )}

      <Flex sx={{ justifyContent: 'space-between' }}>
        <Text sx={{ mb: 0, fontSize: 5, fontWeight: 'bold' }} variant="body">
          {title}
        </Text>

        <Text sx={{ mb: 0, fontSize: 5 }} variant="body">
          {size && `${size} ㎡`}
        </Text>
      </Flex>
    </Flex>
  )
}

const HouseTypes = () => {
  const data = useStaticQuery(graphql`
    {
      images: allFile(filter: { relativeDirectory: { eq: "house-types" } }) {
        nodes {
          name
          childImageSharp {
            fluid(quality: 90, maxWidth: 640) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  `)

  return (
    <Flex
      sx={{
        flexWrap: 'wrap',
        justifyContent: 'space-between',
      }}
    >
      {types &&
        types.map((type) => {
          const image = data.images.nodes
            .filter((node) => node.name === type.imageKey)
            .shift()
          return (
            <Box key={type.id} sx={{ width: ['100%', '48%', '30%'], mb: 4 }}>
              <HouseType
                key={type.id}
                title={type.title}
                size={type.size}
                image={image}
              />
            </Box>
          )
        })}
    </Flex>
  )
}

HouseType.propTypes = {
  title: PropTypes.string.isRequired,
  size: PropTypes.number,
  image: PropTypes.object,
}

export default HouseTypes
