import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Text, Box } from 'rebass/styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/hero'
import Section from '../components/section'
import Container from '../components/container'
import HouseTypes from '../components/house-types'

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "stavebni-cinnost.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Stavební činnost" />

      <Hero
        title="Stavební činnost"
        subtitle="Nabízíme výstavbu rodinných domů „na klíč“ v 6 základních variantách."
        image={data.image}
      />

      <Section>
        <Container>
          <Box>
            <Text variant="body" as="p">
              Nabízíme výstavbu rodinných domů „na klíč“ v 6 základních
              variantách (typ A,B,C,D,E,F), včetně zpracování prováděcí
              projektové dokumentace, vyřízení stavebního povolení a výstavbu
              rodinného domu „na klíč“.
            </Text>
          </Box>

          <HouseTypes />
        </Container>
      </Section>
    </Layout>
  )
}

export default IndexPage
